<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 18:44:51
 * @LastEditTime: 2022-04-08 11:03:17
 * @LastEditors: cyy
 * @Description: 推广员》推广员管理》详情
 * @FilePath: \teacher.dingdingkaike\src\views\MarketingCenter\promoter\promoterDetail.vue
-->
<template>
  <div id="promoterDetail">
    <div class="basic_data">
      <el-descriptions>
        <el-descriptions-item label="推广员">
          <div class="flex-center">
            <div class="tu">
              <img :src="promoterGoodsList.uphoto" alt="" />
            </div>
            <div class="one_overflow" :title="promoterGoodsList.uname">
              {{ promoterGoodsList.uname }}
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="真实姓名">
          <div :title="promoterGoodsList.p_realname" class="one_overflow">
            {{ promoterGoodsList.p_realname }}
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          {{ promoterGoodsList.p_mobile }}
        </el-descriptions-item>
        <!-- <el-descriptions-item label="累计客户(人)">
          {{ promoterGoodsList.deal_num }}
        </el-descriptions-item> -->
        <el-descriptions-item label="累计客户(人)">
          {{ promoterGoodsList.total_customer_num }}
        </el-descriptions-item>
        <el-descriptions-item label="累计订单">
          {{ promoterGoodsList.total_order_num }}
        </el-descriptions-item>
        <el-descriptions-item label="累计佣金金额(元)">
          {{ promoterGoodsList.total_commission }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="main main_menu">
      <el-tabs v-model="activeName" @tab-click="tabsChange">
        <el-tab-pane
          v-for="item in menulist"
          :key="item.name"
          :label="item.label"
          :name="item.name"
        ></el-tab-pane>
      </el-tabs>
      <div class="flex-center pl20 pr20">
        <el-button size="medium" @click="exportTable">导出数据</el-button>
        <div class="header">
          <div class="heard_top mt20">
            <el-select
              style="width: 120px"
              v-model="post.status"
              placeholder="请选择"
              v-show="activeName == 2"
              size="medium"
              class="mr20"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-date-picker
              size="medium"
              v-model="orderTime"
              type="datetimerange"
              :default-time="['00:00:00', '23:59:59']"
              @change="paytimepick"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <!-- 推广记录 -->
      <pagination2
        v-if="activeName == 1"
        :key="1"
        :option="post"
        url="/Promoter/promoterRecord"
        class="pl20 pr20"
        @complete="complete"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="成交时间" prop="number">
              <template slot-scope="{ row }">
                {{
                  row.goods_order_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
                }}
              </template>
            </el-table-column>

            <el-table-column label="商品" prop="number" width="230">
              <template slot-scope="{ row }">
                <div class="cover flex-center">
                  <img class="img" :src="row.goods_cover_url" alt="" />
                  <div class="text">
                    <div class="t1">{{ row.goods_name }}</div>
                    <div class="t1">{{ row.goods_type | getGoodstype }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="成交客户">
              <template slot-scope="{ row }">
                <div class="paymember flex-center">
                  <div class="member_photo">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div class="font1">{{ row.uname }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="订单金额">
              <template slot-scope="{ row }">
                <div>￥{{ row.goods_order_price }}</div>
              </template>
            </el-table-column>

            <el-table-column label="佣金" prop="price">
              <template slot-scope="{ row }">￥{{ row.commission }}</template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <el-button type="text" @click="toOrder(row)">
                  查看订单
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>

      <!--客户关系-->
      <pagination2
        :key="2"
        v-if="activeName == 2"
        :option="post"
        url="/Promoter/promoterCustomer"
        class="pl20 pr20"
        @complete="complete"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
          >
            <el-table-column label="客户">
              <template slot-scope="{ row }">
                <div class="paymember">
                  <div class="member_photo">
                    <img :src="row.uphoto" alt="" />
                  </div>
                  <div class="font1">
                    {{ row.uname }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="剩余有效天数（天）">
              <template slot-scope="{ row }">
                {{ row.expired_day }}
              </template>
            </el-table-column>

            <el-table-column label="最近绑定时间">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>

            <el-table-column label="累计消费(元)">
              <template slot-scope="{ row }">￥{{ row.sum_price }}</template>
            </el-table-column>

            <el-table-column label="状态">
              <template slot-scope="{ row }">
                {{ row.status | getStatus }}
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  @click="
                    $router.push({
                      path: '/manageStudent/detail',
                      query: { uid: row.uid },
                    })
                  "
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'promoterDetail',

  data() {
    return {
      promoterGoodsList: {},

      activeName: '1',

      menulist: [
        {
          label: '推广记录',
          name: '1',
        },
        {
          label: '客户列表',
          name: '2',
        },
      ],

      options: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '已绑定',
        },
        {
          value: 2,
          label: '已解绑',
        },
      ],

      post: {
        status: 0,
        start_time: 0,
        end_time: 0,
        p_user_id: this.p_user_id,
      },

      orderTime: '',

      options3: [
        {
          value: 1,
          label: '推广员昵称',
        },
        {
          value: 2,
          label: '成交客户',
        },
      ],
    }
  },

  filters: {
    // 商品
    getGoodstype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = ' 录播课 '
          break
        case 4:
          text = ' 系列课'
          break
        case 5:
          text = '公开课'
          break
      }
      return text
    },

    // 获取客户关系状态
    getStatus(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '已绑定'
          break
        case 2:
          text = '已解绑'
          break
      }
      return text
    },
  },

  created() {
    this.promoterGoods()
    this.post.p_user_id = this.p_user_id
  },

  computed: {
    p_user_id() {
      return this.$route.query.p_user_id
    },
  },

  methods: {
    tabsChange() {
      this.post = {
        status: 0,
        start_time: 0,
        end_time: 0,
        p_user_id: this.p_user_id,
      }
      this.orderTime = ''
    },

    complete(val) {
      this.count = val.count
    },

    // 查看订单
    toOrder(row) {
      let url = ''
      switch (Number(row.goods_type)) {
        case 1:
        case 2:
        case 3:
        case 4:
          url = '/capitalOrder/detail?data_id='
          break
        case 5:
          url = '/capitalOrder/opencourseDetail/'
          break
      }
      this.$router.push({
        path: url + row.goods_order_id,
      })
    },

    // 获取基础数据
    async promoterGoods() {
      const { data } = await this.$http({
        url: '/Promoter/promoterGoods',
        data: { p_user_id: this.p_user_id },
      })
      this.promoterGoodsList = data
    },

    //付款时间
    paytimepick(val) {
      val = val || [0, 0]
      this.post.start_time = val[0] / 1000
      this.post.end_time = val[1] / 1000
    },

    // 导出
    exportTable() {
      this.$confirm(`确定要导出当前${this.count}条数据？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          // 所有搜索条件get传递过去
          if (this.count > 0) {
            let eleLink = document.createElement('a')
            eleLink.href = `${location.origin}${
              this.activeName == 1
                ? '/Promoter/promoterRecordExcel'
                : '/Promoter/promoterCustomerExcel'
            }${this.objToStr(this.post)}`
            eleLink.click()
            eleLink.remove()
          } else {
            this.$root.prompt('暂无数据，无法导出！')
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
#promoterDetail {
  ::v-deep .el-descriptions-item__label {
    flex-shrink: 0;
  }

  // 超过有一行隐藏
  .one_overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::v-deep .el-descriptions-item__content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .basic_data {
    background-color: #fff;
    padding: 20px;
    padding-bottom: 8px;
    .tu {
      width: 22px;
      height: 22px;
      border-radius: 11px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  ::v-deep .main_menu {
    background-color: #fff;
    .el-tabs__nav-wrap {
      padding: 0 30px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }
}
.main {
  margin-top: 20px;
  background: #fff;
}
.header {
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: -10px;
  ::v-deep .input-with-select .el-input__inner {
    width: 218px !important;
  }
  ::v-deep .search_select {
    .el-input__inner {
      width: 120px !important;
    }
  }
  ::v-deep .el-input-group {
    width: auto;
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
    }
  }
}
.heard_top {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333333;
  span {
    margin-right: 24px;
    min-width: 56px;
  }
  ::v-deep .money_heard_first {
    .el-input-group__append {
      padding: 5px;
      font-size: 14px;
      color: #999999;
      background-color: #fff;
      position: absolute;
      right: 0;
      background: none;
      top: 0;
      height: 100%;
      border: 0;
      line-height: 36px;
      width: auto;
      padding: 0;
      padding-right: 10px;
    }
  }
  ::v-deep .input-with-select {
    .el-input__inner {
      color: #333333;
    }
    .el-input-group__prepend {
      background-color: #fff;
      width: 56px;
    }
  }
}
.paymember {
  display: flex;
  align-items: center;
  .member_photo {
    margin-right: 10px;
    img {
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }
  }
  .font1 {
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    line-height: 17px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cover {
  white-space: nowrap;
  .img {
    width: 80px;
    height: 45px;
    background: #f5f5f5;
    border-radius: 2px;
  }
  .text {
    flex: 1;
    overflow: hidden;
    margin-left: 10px;
    .t1 {
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .t2 {
      margin-top: 8px;
      font-size: 12px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.space {
  color: #0aa29b;
  display: inline-block;
  margin: -3px 10px 0;
}
</style>

 